import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  SetStateAction,
  Dispatch,
  useMemo,
  useRef,
  MutableRefObject,
} from "react";
import firebase from "../utils/firebase";
import { User } from "../interfaces";
import { Loading } from "../components";

type AuthContextProps = {
  user: User | null;
  setUser: Dispatch<SetStateAction<User | null>>;
  isAuthenticating: boolean;
  isAuthenticated: boolean;
  logout: () => void;
  signIn: (email?: string) => Promise<SIGN_IN_RESULT | undefined>;
  signUp: (email: string) => Promise<SIGN_UP_RESULT | undefined>;
  disableAuthStateChangedHandler: MutableRefObject<boolean>;
};

export const AuthContext = createContext<AuthContextProps>(
  {} as AuthContextProps
);

export const LOGIN_ROUTE = "/login";
export const REDIRECT_TO_AFTER_LOGIN = "/";

export enum SIGN_IN_RESULT {
  NO_EMAIL_SPECIFIED,
  SIGN_IN_LINK_SENT,
  NO_USER_FOUND,
  SUCCESS,
}

export enum SIGN_UP_RESULT {
  USER_ALREADY_EXISTS,
  SUCCESS,
}

async function checkIfEmailExists(email: string) {
  const response = await fetch("/api/users/email-exists", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
  });

  if (response.ok) {
    const { exists } = await response.json();
    return exists;
  } else {
    return true;
  }
}

export default function AuthProvider({ children }: any) {
  const [user, setUser] = useState<User | null>(null);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const isAuthenticated = useMemo(() => !!user, [user]);
  const disableAuthStateChangedHandler = useRef(false);

  useEffect(() => {
    const unsubscribeAuthStateChangedListener = firebase
      .auth()
      .onAuthStateChanged(async (firebaseUser) => {
        if (!disableAuthStateChangedHandler.current) {
          try {
            setIsAuthenticating(true);

            if (firebaseUser) {
              const response = await fetch("/api/users/me", {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "Firebase-Token": await firebaseUser.getIdToken(),
                },
              });

              const user = await response.json();
              setUser(user);
            } else {
              setUser(null);
            }
          } catch (error) {
            // Most probably a connection error. Handle appropriately.
          } finally {
            setIsAuthenticating(false);
          }
        } else {
          setIsAuthenticating(false);
        }
      });

    return () => unsubscribeAuthStateChangedListener();
  }, []);

  async function signIn(email?: string) {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      const emailForSignIn = window.localStorage.getItem("emailForSignIn");

      if (!emailForSignIn && !email) {
        return SIGN_IN_RESULT.NO_EMAIL_SPECIFIED;
      }

      await firebase
        .auth()
        .signInWithEmailLink(emailForSignIn ?? email!, window.location.href);

      window.localStorage.removeItem("emailForSignIn");
      return SIGN_IN_RESULT.SUCCESS;
    } else if (email) {
      const emailExists = await checkIfEmailExists(email);

      if (!emailExists) {
        return SIGN_IN_RESULT.NO_USER_FOUND;
      }

      await firebase.auth().sendSignInLinkToEmail(email, {
        handleCodeInApp: true,
        url: window.location.href,
      });

      window.localStorage.setItem("emailForSignIn", email);
      return SIGN_IN_RESULT.SIGN_IN_LINK_SENT;
    }
  }

  async function signUp(email: string) {
    const emailExists = await checkIfEmailExists(email);

    if (emailExists) {
      return SIGN_UP_RESULT.USER_ALREADY_EXISTS;
    }

    await firebase.auth().sendSignInLinkToEmail(email, {
      handleCodeInApp: true,
      url: window.location.href,
    });

    window.localStorage.setItem("emailForSignIn", email);
    return SIGN_UP_RESULT.SUCCESS;
  }

  function logout() {
    firebase.auth().signOut();
  }

  if (isAuthenticating) {
    return <Loading />;
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        isAuthenticating,
        isAuthenticated,
        logout,
        signIn,
        signUp,
        disableAuthStateChangedHandler,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
