import React from "react";

type Props = {
  title: string;
  subtitle: string;
};

export default function PageTitle(props: Props) {
  return (
    <>
      <h2 style={{ marginBottom: 0 }}>{props.title}</h2>
      <p style={{ marginTop: 0 }}>{props.subtitle}</p>
    </>
  );
}
