export function processRows(rows: any[][]) {
  let content = "";

  for (let i = 0; i < rows.length; i++) {
    let finalVal = "";
    for (let j = 0; j < rows[i].length; j++) {
      let innerValue = rows[i][j] === null ? "" : rows[i][j].toString();
      if (rows[i][j] instanceof Date) {
        innerValue = rows[i][j].toLocaleString();
      }
      let result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ",";
      finalVal += result;
    }
    content += finalVal + "\n";
  }

  return content;
}

export function download(filename: string, type: string, content: string) {
  var blob = new Blob([content], { type: `${type};charset=utf-8;` });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
