import React from "react";
import { Button, Modal, useModal } from "@geist-ui/react";
import { ShoppingCart } from "@geist-ui/react-icons";
import { CheckoutForm } from "./";

export default function BuyNow() {
  const { setVisible, bindings } = useModal();

  return (
    <>
      <Button
        icon={<ShoppingCart />}
        style={{ marginRight: "10px" }}
        type="success"
        ghost
        size="mini"
        onClick={() => {
          setVisible(true);
        }}
        auto
      >
        Buy new
      </Button>
      <Modal {...bindings}>
        <Modal.Title>Buy New License</Modal.Title>
        <Modal.Subtitle>
          Prices are per user for unlimited boards
        </Modal.Subtitle>
        <Modal.Content>
          <CheckoutForm />
        </Modal.Content>
        <Modal.Action passive onClick={() => setVisible(false)}>
          Cancel
        </Modal.Action>
      </Modal>
    </>
  );
}
