import { Button } from "@geist-ui/react";
import React, { useState } from "react";
import { db } from "../utils/firebase";

type Props = {
  licenseKey: string;
  onSuccess: () => void;
};

export default function UnlinkButton({
  licenseKey,
  onSuccess,
}: Props): React.ReactElement {
  const [loading, setLoading] = useState(false);

  return (
    <Button
      type="error"
      ghost
      loading={loading}
      size="mini"
      onClick={async () => {
        if (window.confirm("Are you sure?")) {
          setLoading(true);
          await db
            .collection("licenses")
            .doc(licenseKey)
            .update({ trelloMemberId: null, trelloMemberFullName: null });

          onSuccess();
          setLoading(false);
        }
      }}
    >
      unlink
    </Button>
  );
}
