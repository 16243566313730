import React from "react";

import { Text } from "@geist-ui/react";

type Props = {
  text?: string;
};

export default function FieldError(props: Props) {
  return (
    <Text small type="error" style={{ display: "block" }}>
      {props.text || "This field is required"}
    </Text>
  );
}
