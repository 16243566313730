import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useAuth } from "./../contexts/AuthContext";

type PrivateRouteProps = RouteProps;

export default function PrivateRoute({
  children,
  ...props
}: PrivateRouteProps): React.ReactElement {
  const { isAuthenticated } = useAuth();

  return (
    <Route
      {...props}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: `/login`,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
