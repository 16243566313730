import React from "react";
import { Checkbox } from "@geist-ui/react";
import { Controller } from "react-hook-form";

export default function ControlledCheckbox({
  control,
  name,
  rules,
  ...restProps
}: any): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ onChange, onBlur, value }) => (
        <Checkbox
          checked={value}
          onChange={(e) => onChange(!!e.target.checked)}
          onBlur={onBlur}
          {...restProps}
        />
      )}
    />
  );
}
