import React, { useCallback } from "react";
import {
  Button,
  Badge,
  Spacer,
  Text,
  Link,
  Radio,
  useTheme,
} from "@geist-ui/react";
import { ArrowRight } from "@geist-ui/react-icons";

import { FieldError } from ".";
import { useForm } from "react-hook-form";
import { useAuth } from "../contexts/AuthContext";
import ControlledRadioGroup from "./ControlledRadioGroup";

export default function CheckoutForm() {
  const {
    errors,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      plan: process.env.REACT_APP_MONTHLY_PLAN_ID,
    },
  });

  const { user } = useAuth();

  const checkout = useCallback(
    async ({ plan }) => {
      const { email } = user!;
      (window as any).Paddle.Checkout.open({
        product: plan,
        email,
        success: "/?success",
      });
    },
    [user]
  );

  const theme = useTheme();

  return (
    <form
      onSubmit={handleSubmit(checkout)}
      style={{ padding: "0 10px", textAlign: "center" }}
    >
      <Text p style={{ marginTop: 0 }}>
        Choose a plan
      </Text>
      <Spacer y={0.5} />
      <ControlledRadioGroup
        name="plan"
        control={control}
        rules={{ required: true }}
        useRow
        style={{ textAlign: "left" }}
      >
        <Radio value={process.env.REACT_APP_MONTHLY_PLAN_ID}>
          <>
            Monthly
            <Badge
              size="mini"
              type="success"
              style={{
                marginLeft: "4px",
                backgroundColor: theme.palette.cyanLighter,
                color: theme.palette.foreground,
              }}
            >
              popular
            </Badge>
          </>
          <Radio.Desc>$6/month</Radio.Desc>
        </Radio>
        <Radio value={process.env.REACT_APP_YEARLY_PLAN_ID}>
          <>
            Annual
            <Badge
              size="mini"
              style={{
                marginLeft: "4px",
                backgroundColor: theme.palette.violet,
              }}
            >
              67% off
            </Badge>
          </>
          <Radio.Desc>$24/year (only $2/month)</Radio.Desc>
        </Radio>
      </ControlledRadioGroup>
      {errors.plan && <FieldError text={errors.plan.message} />}
      <Spacer y={1} />
      <Text size={12}>
        By clicking continue, you agree to the{" "}
        <Link href={process.env.REACT_APP_TERMS_URL} color target="_blank">
          Terms of Service
        </Link>{" "}
        and{" "}
        <Link href={process.env.REACT_APP_PRIVACY_URL} color target="_blank">
          Privacy Policy
        </Link>
        .
      </Text>
      <Spacer y={0.5} />
      <Button
        loading={isSubmitting}
        icon={<ArrowRight />}
        iconRight
        htmlType="submit"
        type="success"
      >
        Continue
      </Button>
    </form>
  );
}
