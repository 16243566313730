import React from "react";
import { Page, Row, Col, Link, Text, Image } from "@geist-ui/react";

export default function Footer() {
  return (
    <Page.Footer>
      <Row>
        <Col>
          <Text size={12} type="secondary">
            Copyright © {new Date().getFullYear()}&nbsp;&nbsp;
            <Link href="https://mig.team" target="_blank">
              Mig
            </Link>
          </Text>
        </Col>
        <Col style={{ textAlign: "right" }}>
          <Text size={12} type="secondary">
            <Link href={process.env.REACT_APP_TERMS_URL} color target="_blank">
              Terms of Service
            </Link>
            &nbsp;&nbsp;/&nbsp;&nbsp;
            <Link
              href={process.env.REACT_APP_PRIVACY_URL}
              color
              target="_blank"
            >
              Privacy Policy
            </Link>
          </Text>
        </Col>
      </Row>
    </Page.Footer>
  );
}
