import React from "react";
import {
  Page,
  Row,
  Col,
  Button,
  Divider,
  Text,
  Popover,
  Link,
} from "@geist-ui/react";
import { Home, User } from "@geist-ui/react-icons";
import { useAuth } from "../contexts/AuthContext";
import { NavLink, useHistory } from "react-router-dom";

export default function Header() {
  const { user, logout, isAuthenticated } = useAuth();
  let history = useHistory();

  const content = () => (
    <>
      <Popover.Item title>
        <span style={{ width: "160px" }}>My Account</span>
      </Popover.Item>
      <Popover.Item>
        <NavLink to="/">Manage licenses</NavLink>
      </Popover.Item>
      <Popover.Item>
        <NavLink to="/settings">Settings</NavLink>
      </Popover.Item>
      <Popover.Item line />
      <Popover.Item>
        <Link href="#" onClick={logout}>
          Logout
        </Link>
      </Popover.Item>
    </>
  );

  return (
    <Page.Header>
      <Row style={{ paddingTop: "16px" }}>
        <Col>
          <Link href={process.env.REACT_APP_LP_URL} target="_blank">
            <Button icon={<Home />} auto size="small" type="secondary"></Button>
            <Text span type="secondary" style={{ marginLeft: "10px" }}>
              {process.env.REACT_APP_PRODUCT_NAME}
            </Text>
          </Link>
        </Col>
        <Col style={{ textAlign: "right" }}>
          {isAuthenticated && (
            <>
              <Link
                href="mailto:support@smart-import.app"
                style={{ fontSize: "14px", color: "#666", marginRight: "20px" }}
              >
                Support
              </Link>
              <Popover content={content}>
                <Button
                  icon={<User />}
                  auto
                  size="small"
                  style={{ textTransform: "lowercase" }}
                >
                  {" "}
                  {user && user.email}
                </Button>
              </Popover>
            </>
          )}
          {isAuthenticated !== true && (
            <>
              <NavLink
                to="/checkout"
                style={{ fontSize: "14px", marginRight: "20px" }}
              >
                Buy now
              </NavLink>
              <NavLink
                to="/login"
                style={{ fontSize: "14px", color: "#666", marginRight: "20px" }}
              >
                Log in
              </NavLink>
              <Button
                auto
                size="small"
                type="secondary"
                onClick={(e) => history.push("/signup")}
              >
                Sign Up
              </Button>
            </>
          )}
        </Col>
      </Row>
      <Divider />
    </Page.Header>
  );
}
