import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";
import { License } from "../interfaces";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_PUBLIC_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

if (typeof window !== "undefined" && !firebase.apps.length) {
  firebase.initializeApp(config);

  if ("measurementId" in config) firebase.analytics();
}

export default firebase;

export const db = firebase.firestore();

export function convertDocumentToLicense(
  doc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
): License {
  const expiresAt = doc.get("expiresAt").toDate();
  const trelloMemberId = doc.get("trelloMemberId");
  const trelloMemberFullName = doc.get("trelloMemberFullName");
  const updateUrl = doc.get("updateUrl");
  const cancelUrl = doc.get("cancelUrl");

  return {
    key: doc.id,
    expiresAt,
    trelloMemberId,
    trelloMemberFullName,
    updateUrl,
    cancelUrl,
  };
}
