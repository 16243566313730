import React, { useCallback } from "react";
import {
  Page,
  Text,
  Button,
  Divider,
  Card,
  useToasts,
  Spacer,
} from "@geist-ui/react";

import { useAuth } from "../contexts/AuthContext";
import { ControlledCheckbox, Footer, Header, PageTitle } from "../components";
import { useForm } from "react-hook-form";

export default function Settings() {
  const { user } = useAuth();
  const [, setToast] = useToasts();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      optInOfMarketing: !(user!.optOutOfMarketing ?? false),
    },
  });

  const save = useCallback(
    async ({ optInOfMarketing }) => {
      const { uid } = user!;

      const response = await fetch("/api/update-customer", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: uid,
          optOutOfMarketing: !optInOfMarketing,
        }),
      });

      if (response.ok) {
        setToast({
          text: "Successfully saved settings!",
          type: "success",
        });
      } else {
        setToast({
          text: await response.text(),
          type: "error",
        });
      }
    },
    [user, setToast]
  );

  return (
    <>
      <Page size="large">
        <Header />
        <Page.Content style={{ paddingTop: "2em" }}>
          <PageTitle title="Settings" subtitle="Change your preferences" />
          <Divider />
          <form onSubmit={handleSubmit(save)}>
            <Card>
              <Text h4 style={{ marginBottom: "0" }}>
                Email notifications
              </Text>
              <Spacer y={1} />
              <ControlledCheckbox name="optInOfMarketing" control={control}>
                I want to receive news, feature updates, discounts, and offers
                from Mig.
              </ControlledCheckbox>
              <Card.Footer>
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="secondary"
                  htmlType={"submit"}
                  auto
                >
                  Save
                </Button>
              </Card.Footer>
            </Card>
          </form>
        </Page.Content>
        <Footer />
      </Page>
    </>
  );
}
