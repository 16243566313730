import React from "react";
import {
  Grid,
  Spinner
} from "@geist-ui/react";

export default function Loading() {
  return (
    <Grid.Container justify="center" alignItems="center" style={{ height: '100vh' }}>
      <Grid>
        <Spinner size="large" />
      </Grid>
    </Grid.Container>
  );
}
