import React, { useCallback, useEffect, useState } from "react";
import {
  Page,
  Text,
  Row,
  Col,
  Spacer,
  Input,
  Button,
  Link,
  useToasts,
} from "@geist-ui/react";
import { Mail } from "@geist-ui/react-icons";
import { Header, Footer, FieldError, ControlledCheckbox } from "../components";

import { Redirect, useHistory } from "react-router-dom";
import {
  REDIRECT_TO_AFTER_LOGIN,
  SIGN_IN_RESULT,
  SIGN_UP_RESULT,
  useAuth,
} from "../contexts/AuthContext";
import firebase from "../utils/firebase";
import { useForm } from "react-hook-form";
import { validateEmailFormat } from "../utils/misc";

type Props = {
  isSignUp?: boolean;
};

export default function Login({ isSignUp }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [, setToast] = useToasts();
  const { isAuthenticated, signIn, signUp } = useAuth();

  const { register, errors, control, setValue, reset, handleSubmit } = useForm({
    defaultValues: {
      email: "",
      accept: false,
    },
  });

  let history = useHistory();

  const tryToSignIn = useCallback(
    async (email: string) => {
      setIsLoading(true);

      try {
        const response = await signIn(email);

        switch (response) {
          case SIGN_IN_RESULT.NO_EMAIL_SPECIFIED:
            setToast({
              text: "Please provide your email for confirmation.",
              type: "warning",
            });
            break;

          case SIGN_IN_RESULT.NO_USER_FOUND:
            setToast({
              text: "No user found with the specified email.",
              type: "warning",
              delay: 5000,
              actions: [
                {
                  name: "Sign up",
                  handler: () => history.push("/signup"),
                },
              ],
            });
            break;

          case SIGN_IN_RESULT.SIGN_IN_LINK_SENT:
            setToast({
              text: "We sent a sign in link! Check your inbox",
              type: "success",
            });
            reset();
            break;

          case SIGN_IN_RESULT.SUCCESS:
            history.push(REDIRECT_TO_AFTER_LOGIN);
            setToast({
              text: "You've successfully signed in!",
              type: "success",
            });
            reset();
            break;
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setToast({
          text: "Something went wrong. Try again later",
          type: "error",
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const tryToSignUp = useCallback(
    async (email: string) => {
      setIsLoading(true);

      try {
        const response = await signUp(email);

        switch (response) {
          case SIGN_UP_RESULT.USER_ALREADY_EXISTS:
            setToast({
              text: "There is an user with the specified email.",
              type: "warning",
              delay: 5000,
              actions: [
                {
                  name: "Log in",
                  handler: () => history.push("/login"),
                },
              ],
            });
            break;

          case SIGN_UP_RESULT.SUCCESS:
            setToast({
              text: "You've successfully signed up! Check your inbox to login.",
              type: "success",
            });
            reset();
            break;
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setToast({
          text: "Something went wrong. Try again later",
          type: "error",
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      setValue("email", window.localStorage.getItem("emailForSignIn") ?? "");
      tryToSignIn(window.localStorage.getItem("emailForSignIn") ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }

  return (
    <Page size="large">
      <Header />
      <Page.Content>
        <form
          onSubmit={handleSubmit(({ email }) => {
            return isSignUp ? tryToSignUp(email) : tryToSignIn(email);
          })}
        >
          <Row justify="center">
            <Col style={{ textAlign: "center" }}>
              <Text h1>{isSignUp ? "Sign Up" : "Sign in using e-mail"}</Text>
              <Spacer y={1} />
              <Input
                name="email"
                type="email"
                disabled={isLoading}
                placeholder="you@domain.com"
                width="400px"
                size="large"
                icon={<Mail />}
                ref={register({
                  required: "This field is required",
                  validate: (value: string) =>
                    validateEmailFormat(value) ||
                    "This e-mail address is incorrect",
                })}
                {...(errors.email && {
                  status: "error",
                })}
              />
              {errors.email && <FieldError text={errors.email.message} />}
              <Spacer y={1} />
              {isSignUp && (
                <>
                  <ControlledCheckbox
                    name="accept"
                    control={control}
                    rules={{ required: isSignUp }}
                  >
                    I agree to the{" "}
                    <Link
                      href={process.env.REACT_APP_TERMS_URL}
                      color
                      target="_blank"
                    >
                      Terms of Service
                    </Link>{" "}
                    and{" "}
                    <Link
                      href={process.env.REACT_APP_PRIVACY_URL}
                      color
                      target="_blank"
                    >
                      Privacy Policy
                    </Link>
                    .
                  </ControlledCheckbox>
                  {errors.accept && <FieldError />}
                  <Spacer y={1} />
                </>
              )}
              <Button
                loading={isLoading}
                htmlType="submit"
                auto
                type="secondary"
              >
                {isSignUp ? "Sign Up" : "Sign in"}
              </Button>
            </Col>
          </Row>
        </form>
      </Page.Content>
      <Footer />
    </Page>
  );
}
